<template>
  <MDBAccordion v-model="activeItem" dadex>

      
    <MDBAccordionItem
      headerTitle="What is Tamil data decentralization?"
      collapseId="dadex-1"
    >
    <div class="faqAnswer">
      
      <a href='https://tamiltoken.medium.com/tamil-token-%E0%AE%93%E0%AE%B0%E0%AF%8D-%E0%AE%85%E0%AE%B1%E0%AE%BF%E0%AE%AE%E0%AF%81%E0%AE%95%E0%AE%AE%E0%AF%8D-a55c2b26e41b'>Click this link for more information.</a>
    </div>
    </MDBAccordionItem>

      
    <MDBAccordionItem
      headerTitle="What was the key motivation behind the decentralization of Tamil digital assets project?  We do have individuals and organizations hosting digital copies of documents, so why do we need this Tamil Crypto Project, and how is it different?"
      collapseId="dadex-9"
    >
    <div class="faqAnswer">

      <p>The burning of the Jaffna Public Library in Sri Lanka on May 31, 1981, was a tragic event that caused significant cultural and intellectual loss. The library was a renowned repository of Tamil literature and historical documents, and its destruction was a severe blow to the Tamil community in Sri Lanka. The Jaffna library was a source of knowledge about Tamil culture in Sri Lanka and contained over 97,000 books and manuscripts!</p>

      <p>We could have saved and revived these resources if we had digital copies of these documents. You may ask, "Don't we have digital formats of documents now?"</p>

      <p>Yes, we do, but they are centralized, prone to expiration, and subject to the following:</p>

      <ol>
          <li>Technical errors in the server hosting digital documents, which may result in errors or server abandonment due to storage issues or failure to renew.</li>
          <li>Infiltration and sabotage by other ethnic communities that could destroy or alter content.</li>
          <li>Planned attacks or destruction of data stored on servers.</li>
          <li>Content posted on social media platforms may be subject to removal and censorship.</li>
      </ol>

      <p>Decentralization addresses these issues and ensures that someone always has a copy. We ensure this by incentivizing participants in the network using Tamil Crypto.</p>

      </div>
    </MDBAccordionItem>

   
    <MDBAccordionItem
      headerTitle="I am interested in volunteering to decentralize digital assets of the Tamil language. How do I join?"
      collapseId="dadex-2"
    >
    <div class="faqAnswer">
      Visit https://app.tamilcrypto.org and set up an account. Information on the steps will be emailed to you.
    </div> 
    </MDBAccordionItem>


    <MDBAccordionItem
      headerTitle="Is there any training for data decentralization?"
      collapseId="dadex-2"
    >
    <div class="faqAnswer">
      Yes, we provide weekly online training free of charge. You can visit the link below and learn at your own pace, or attend one of our weekly classes. 
      Subscribe to our Telegram channel <a href='https://t.me/tamiltoken'>@tamiltoken</a> for updates.
     </div>
    </MDBAccordionItem>



    <MDBAccordionItem
      headerTitle="Are there any rewards for contributing to the Tamil Digital Asset Decentralization project?"
      collapseId="dadex-3"
    >
    <div class="faqAnswer">
  
    Yes, we will send weekly payouts to your wallet. Rewards will be calculated based on your contribution to the Data Decentralization project.
    </div>

    </MDBAccordionItem>

    <MDBAccordionItem
      headerTitle="Who owns the digital assets archived here?"
      collapseId="dadex-4"
    >
    <div class="faqAnswer">
      Decentralized electronic archives belong to everyone. There is no single owner of these archives, which is all the more reason for you to join. This project is neither a commercial nor a governmental effort.
    </div>
    </MDBAccordionItem>

    
    <MDBAccordionItem
      headerTitle="In what format do we need to upload digital assets? Can I include images?"
      collapseId="dadex-5"
    >
    <div class="faqAnswer">
    All text must be typed in Unicode font, and relevant images can be attached to the document. 
    The final output must be in PDF format. Each uploaded file must not exceed 10 MB in size. 
    We have chosen PDF as the preferred option because it is portal-based, cross-platform, and device-independent.
    </div>
    </MDBAccordionItem>
    
    <MDBAccordionItem
      headerTitle="I would like to join, but I'm not sure if I can work regularly. Can I still contribute?"
      collapseId="dadex-6"
    >
    <div class="faqAnswer">
    Tamil Digital Data Decentralization is a completely voluntary effort, so we understand that you may have other commitments. We completely respect this and never compel anyone at any time. Feel free to join and contribute during your free time and whenever you wish.
    Remember, consistent contributions will be rewarded using Tamil Crypto Tokens. There are many ways to contribute; at the very least, you can become a seeder to download copies of digital assets and store them on your computer.
    </div>
    </MDBAccordionItem>

    <MDBAccordionItem
      headerTitle="How is Tamil digital content chosen for decentralization? Is there any preference?"
      collapseId="dadex-7"
    >
    <div class="faqAnswer">
    It is entirely up to the volunteer to choose a work to add to the decentralization archive. 
    We do not force anyone to take up any work. Our technology ensures that there will be only one copy; 
    there won't be any duplicate copies. We encourage digital content from the public domain, original creations (content from authors), 
    and collections of public events related to the Tamil language.
    </div>
    </MDBAccordionItem>
   
    <MDBAccordionItem
      headerTitle="Where can I see the list of decentralized digital asset collections?"
      collapseId="dadex-8"
    >
    <div class="faqAnswer">
    To simplify content exploration for visitors, we have created a catalog of decentralized digital content archived by category. 
    You can visit <a href='/archive'>Archives</a>to explore.
  </div>
    </MDBAccordionItem>

    


  </MDBAccordion>
</template>

  
<script>
  import { MDBAccordion, MDBAccordionItem } from "mdb-vue-ui-kit";
  import { ref } from "vue";
  export default {
    components: {
      MDBAccordion,
      MDBAccordionItem
    },
    setup(){
      const activeItem = ref('dadex-1');
      return {
        activeItem
      }
    }
  };
</script>

<style scoped>
.faqAnswer {
  text-align: left;
}
</style>