<template>
  <MDBAccordion v-model="activeItem" flush>

      
    <MDBAccordionItem
      headerTitle="What is TAMIL Crypto?"
      collapseId="collapse1"
    >
    <div class="faqAnswer">
      <strong>The TAMIL Crypto (TAMIL)</strong> is a blockchain-based system for rewarding 
      community members for  activities involved in preserving and developing the world’s 
      oldest living classing language.Its goal is to efficiently distribute 
      TAMIL Cryptos between community projects, decentralized data node operators, and content 
      publishers.
    </div>

    </MDBAccordionItem>

   

    <MDBAccordionItem
      headerTitle="What kind of Token is TAMIL Crypto?"
      collapseId="collapse2"
    >
      <div class="faqAnswer">
      TAMIL Crypto is a utility token. This crypto token can be purchased from decentralized crypto exchanges and used within the Tamil Crypto ecosystem.
      The main purpose of this token is to serve as a rewarding mechanism for activities related to the enrichment of the Tamil language.
      </div>
    </MDBAccordionItem>
    

    <MDBAccordionItem
      headerTitle="When will TAMIL Crypto be listed on exchanges?"
      collapseId="collapse3"
    >
    <div class="faqAnswer">
    TAMIL Crypto has been listed and available in following crypto exchanges.
    <br>
    1. <a href='https://stellarterm.com/exchange/TAMIL-GCLWZL54VOAT3XU4AQCB5QEZSE326CHB2R4PROLRGTYIOVQMNH7YUFSL/XLM-native' target='_blank'> Stellarterm.com</a> <br>
    2. <a href='https://interstellar.exchange/app/#/trade/guest/TAMIL/GCLWZL54VOAT3XU4AQCB5QEZSE326CHB2R4PROLRGTYIOVQMNH7YUFSL/XLM/native' target='_blank'> Interstellar.com </a> <br>
    3. <a href='https://lobstr.co/' target='_blank'>lobstr.co</a> <br>

   </div>
    </MDBAccordionItem>

    <MDBAccordionItem
      headerTitle="Where can I view the roadmap for platform development and future features?"
      collapseId="collapse4"
    >
    <div class="faqAnswer">
    Kindly refer to our road map or refer to our official White Paper for detailed plan.
    <a href='/roadmap'>Development Roadmap </a>
    </div>
    </MDBAccordionItem>


    <MDBAccordionItem
      headerTitle="Why 'ழ' (ZHA) Symbol?"
      collapseId="collapse5"
    >
    <div class="faqAnswer">
          <p>
            Of the 247 Letters in Tamil alphabets, 'ZHA' is the most significant, because of its pronounciation and usage.
          </p>
          <p>
          ழ (ZHA) is the 30th letter of the Tamil alphabet.There are two other letters with, similar sound - LA & LLA, But 'ZHA' is very special ZHAGARAM because of its unique pronunciation.
          </p>
    </div>
    </MDBAccordionItem>


    <MDBAccordionItem
      headerTitle="How TAMIL Cryptos are distributed?"
      collapseId="collapse6"
    >
    <div class="faqAnswer">
          <a href="/#tokendist">Click here to view Tamil Crypto distribution information.  </a>
    </div>
    </MDBAccordionItem>


    <MDBAccordionItem
      headerTitle="What are the key projects of the Tamil Crypto blockchain project?"
      collapseId="collapse7"
    >
    <div class="faqAnswer">
          <a href="/projects">Please refer to this link for more information </a>
    </div>
    </MDBAccordionItem>

    
    <MDBAccordionItem
      headerTitle="What is the purpose of Project Tamil Crypto ?"
      collapseId="collapse8"
    >
    <div class="faqAnswer">
      The main purpose is to preserve the precious collection of Tamil literature, individual content, and records of 
      Tamil language-related events from all over the world. Through this effort, we hope to build a decentralized 
      electronic library of Tamil digital assets and pass it on to future generations.
    </div>
    </MDBAccordionItem>

    <MDBAccordionItem
      headerTitle="Is there a support group or social media channels for this project?"
      collapseId="collapse9"
    >
    <div class="faqAnswer">
      Yes, you can visit our official Facebook page or Telegram channel to ask questions. We also have Reddit and official Twitter accounts. Please refer to the social media section of our website for links.
    </div>
    </MDBAccordionItem>

 
    <MDBAccordionItem
      headerTitle="I have a suggestion. Whom can I contact?"
      collapseId="collapse10"
    >
    <div class="faqAnswer">
      You can reach out to the management team at support@tamilcrypto.org or contact us via Telegram at @tamiltoken.
    </div>
    </MDBAccordionItem>
    
    <MDBAccordionItem
      headerTitle="How do you differ from projectmadurai.org?"
      collapseId="collapse11"
    >
    <div class="faqAnswer">
      We greatly appreciate and respect the initiative of Dr. K. Kalyanasundaram for starting the digitalization process for Tamil literature. In fact, we complement their work by extending it using web 3.0 technologies. The Tamil Crypto project also covers a broader scope. We don't limit content sharing/distribution to only literature; we encourage anyone to upload any useful document in the Tamil language. It can be your own work (for example, if you publish any poem or composition), as well as collections of general Tamil events and their descriptions.

      The rewarding mechanism is the key differentiator when it comes to the Tamil Crypto project.
      <br><br>
      <p>Ultimately, our goal is to protect and pass on the digital assets of the Tamil language to the next generation.</p>
    </div>

    </MDBAccordionItem>
    

  </MDBAccordion>
</template>

  
<script>
  import { MDBAccordion, MDBAccordionItem } from "mdb-vue-ui-kit";
  import { ref } from "vue";
  export default {
    components: {
      MDBAccordion,
      MDBAccordionItem
    },
    setup(){
      const activeItem = ref('flush-collapseOne');
      return {
        activeItem
      }
    }
  };
</script>


<style scoped>
.faqAnswer {
  text-align: left;
}
</style>