
<template>
<hr>
<h5> Frequently Asked Questions</h5>
<hr>
  <MDBTabs v-model="activeTabId2">
    <!-- Tabs navs -->
    <MDBTabNav fill tabsClasses="mb-3">
      <MDBTabItem tabId="ex2-1" href="ex2-1">General</MDBTabItem>
      <MDBTabItem tabId="ex2-2" href="ex2-2">Decentralization</MDBTabItem>
      <MDBTabItem tabId="ex2-3" href="ex2-3">Community</MDBTabItem>
      <MDBTabItem tabId="ex2-4" href="ex2-4">Copyright</MDBTabItem>
    </MDBTabNav>
    <!-- Tabs navs -->
    <!-- Tabs content -->
    <MDBTabContent>
      <!--General Tab-->
      <MDBTabPane tabId="ex2-1">
        <FAQGeneral />
      </MDBTabPane>

      <!--Private Sale-->
      <MDBTabPane tabId="ex2-2">
          <FAQDataDecentralization />
      </MDBTabPane>

       <!--Pre-ICO-->
      <MDBTabPane tabId="ex2-3">
        <FAQCommunity />
      </MDBTabPane>

      <!--Tokens-->
      <MDBTabPane tabId="ex2-4">
        <FAQCopyright/>
      </MDBTabPane>

    </MDBTabContent>
    <!-- Tabs content -->
  </MDBTabs>

  <hr>

  <p>We thank <a href='https://www.linkedin.com/in/kenwyn/' target="_blank">Ms. Sivagami Ganesan</a> for her help in assembling this list of FAQs.</p>
  <p>Last updated: June 02, 2024/Created: May 02, 2022.</p>
</template>




<script>
  import FAQGeneral from "@/components/FAQGeneral"
   import FAQDataDecentralization from "@/components/FAQDataDecentralization"
   import FAQCommunity from "@/components/FAQCommunity"
   import FAQCopyright from "@/components/FAQCopyright"

  
  import {
    MDBTabs,
    MDBTabNav,
    MDBTabContent,
    MDBTabItem,
    MDBTabPane
  } from 'mdb-vue-ui-kit';
  import { ref } from 'vue';

  export default {
    components: {
      MDBTabs,
      MDBTabNav,
      MDBTabContent,
      MDBTabItem,
      MDBTabPane,
      FAQGeneral,
      FAQDataDecentralization,
      FAQCommunity,
      FAQCopyright
    },
    setup() {
      const activeTabId2 = ref('ex2-1');

      return {
        activeTabId2,
      };
    },
  };
</script>