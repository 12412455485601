<template>
  <MDBAccordion v-model="activeItem" copyright>

      
    <MDBAccordionItem
      headerTitle="How does the Tamil Crypto platform handle copyright issues?"
      collapseId="copyright-1"
    >

    <div class="faqAnswer">
      <p>Tamil Crypto Data Decentralization strictly adheres to copyright standards. </p>
      <p>All the works in the collection are either from the public domain (after a certain period following their creation, currently about 75 years after the lifespan of the author) or are included with due consent from the respective authors.</p>

      <p>Our validators have been trained to analyze copyrighted materials and notify the uploader if there are any issues. <br>
      Tamil Crypto just offers a platform for hosting user-generated content.<br>
       If you find any documents listed without consent from the original author, you can always visit the archives and request their removal. <br>
       Alternatively, you can email support@tamilcrypto.org </p>


       <hr>

      <h5>Copyright Compliance and Legal Protection Statement</h5>
      <p>As a platform provider, Tamil Crypto is committed to ensuring compliance with copyright laws and protecting the rights of content creators. To legally protect ourselves from copyright claims related to user-generated content, we adhere to the following practices:</p>
      <ul>
          <li><strong>User Responsibility</strong>: Users are solely responsible for ensuring that the content they upload does not violate copyright laws. By uploading content, users affirm that they either own the rights to the content or have obtained necessary permissions from the copyright holders.</li>
          <li><strong>Content Review</strong>: Our trained validators analyze uploaded content for potential copyright violations. If any issues are identified, the uploader will be notified, and the content may be removed or corrected.</li>
          <li><strong>Copyright Infringement Claims</strong>: We provide a clear process for copyright holders to report any content that they believe infringes on their rights. If you find any documents listed without the consent of the original author, please visit our archives section and request removal, or email us at <a href="mailto:support@tamilcrypto.org">support@tamilcrypto.org</a>.</li>
          <li><strong>DMCA Compliance</strong>: Tamil Crypto complies with the Digital Millennium Copyright Act (DMCA) and other applicable copyright laws. We promptly respond to valid DMCA takedown notices and other legal requests.</li>
          <li><strong>Indemnity Clause</strong>: By using our platform, users agree to indemnify and hold Tamil Crypto harmless from any claims, damages, and expenses arising from their uploaded content.</li>
          <li><strong>Educational and Non-Commercial Use</strong>: We encourage the use of our platform for educational and non-commercial purposes. Content that serves the public interest and contributes to the enrichment of the Tamil language and culture is highly valued.</li>
      </ul>
      <p>By implementing these measures, Tamil Crypto aims to foster a respectful and legally compliant environment for sharing and preserving Tamil digital assets. Thank you for helping us protect and celebrate Tamil heritage.</p>

      
    </div>

    </MDBAccordionItem>



  </MDBAccordion>
</template>

  
<script>
  import { MDBAccordion, MDBAccordionItem } from "mdb-vue-ui-kit";
  import { ref } from "vue";
  export default {
    components: {
      MDBAccordion,
      MDBAccordionItem
    },
    setup(){
      const activeItem = ref('copyright-2');
      return {
        activeItem
      }
    }
  };
</script>

<style scoped>
.faqAnswer {
  text-align: left;
}
</style>