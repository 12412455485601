<template>
  <MDBAccordion v-model="activeItem" flush>

    <MDBAccordionItem
      headerTitle="Why should I join ?  What do I gain ?"
      collapseId="flush-1"
    >
    <div class="faqAnswer">
      <p>You will gain personal satisfaction from helping to protect and preserve Tamil digital assets.  </p>
      <p>You will be rewarded using Tamil Crypto token for your time and effort.</p>

      <p>
        As the following Tamil poem states, "சிறு துளி பெருவெள்ளம்" [Little drops of water make a mighty ocean], every little contribution towards this project will benefit future generations of the Tamil language and community.<br>
        Additionally, your name will be displayed alongside the work you volunteered for, whether it involves typing or validation (proofreading).

      </p>
      </div>
    </MDBAccordionItem>
    

    <MDBAccordionItem
      headerTitle="I want to invite my friends to this project. How can I do that?"
      collapseId="flush-2"
    >
      <div class="faqAnswer">
        You can log in to your web app or mobile app and use the Referral feature.
      </div>
    </MDBAccordionItem>

    <MDBAccordionItem
      headerTitle="I am a social media influencer, and I would like to feature and talk about this project. How can I do that?"
      collapseId="flush-3"
    >
    <div class="faqAnswer">
      We welcome social media influencers, and there will be a special rewarding mechanism for your contribution. Please contact our team at support@tamilcrypto.org or connect with us on Telegram at @tamiltoken.
    </div>
    </MDBAccordionItem>



    <MDBAccordionItem
      headerTitle="How many members are currently involved in the Tamil Crypto project? Is there any qualification required to join the project?"
      collapseId="flush-5"
    >
      <div class="faqAnswer">
      No, we welcome Tamil enthusiasts and patriots from all over the world. Age doesn't matter as long as volunteers abide by a set of instructions to ensure the provision of quality content for upcoming generations. We encourage everyone to join and contribute; you can be uploaders, validators, or seeders.
      </div>
    </MDBAccordionItem>
    
<!--
    <MDBAccordionItem
      headerTitle="Who manages this project? Who are the members of the management team and the coordinators?"
      collapseId="flush-6"
    >
    Project Tamil Crypto is led by <a href='https://www.linkedin.com/in/kavashgar/' target="_blank">Manimarpan Kavashgar </a>for technical aspects. The management team consists of Dr. A.K. Balamurugan and Dr. Gobi. You can also find a list of regional coordinators on this page.

    </MDBAccordionItem>
-->

    <MDBAccordionItem
      headerTitle="I represent a Tamil association. How can I contact and learn more about this project?"
      collapseId="flush-7"
    >
    <div class="faqAnswer">
    We welcome Tamil associations from all over the world. The success of this project depends greatly on the collaborative efforts of Tamil individuals and associations globally. Your information will be listed under the partners section of the TamilCrypto.org website.
    </div>
    </MDBAccordionItem>
    
    

  </MDBAccordion>
</template>

  
<script>
  import { MDBAccordion, MDBAccordionItem } from "mdb-vue-ui-kit";
  import { ref } from "vue";
  export default {
    components: {
      MDBAccordion,
      MDBAccordionItem
    },
    setup(){
      const activeItem = ref('flush-collapseOne');
      return {
        activeItem
      }
    }
  };
</script>


<style scoped>
.faqAnswer {
  text-align: left;
}
</style>